import React, { useEffect } from "react";
import Layout from "../components/layout";
import { useAuth0 } from "@auth0/auth0-react";

const Login = () => {
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		const isBrowser = () => typeof window !== "undefined";
		if (isBrowser()) {
			loginWithRedirect({ returnTo: window.location.origin });
		}
	}, [loginWithRedirect]);

	return (
		<Layout title="Login">
			<h1>Logging you in...</h1>
		</Layout>
	);
};

export default Login;
